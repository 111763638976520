import phones from "assets/phones.svg";
import { Button } from "components/ui/button";

export default function AppDetails() {
  return (
    <div className="flex flex-col sm:flex-row relative py-12 bg-[#FAFAFA] sm:mb-32 mt-32 md:px-[5%]">
      <div className="absolute max-h-4 w-[calc(100%-2rem)] -top-20 sm:-top-14 sm:left-6 md:left-[10%] text-center sm:w-2/5">
        <img className="w-full h-auto max-h-[760px] sm:h-[550px] mx-auto" src={phones}></img>
      </div>
      <div className="px-[5%] xs:mt-[130%] 465px:mt-[600px] sm:mt-0 sm:ml-[45%] [&>p]:pt-6 w-full sm:w-3/5 sm:pr-[5%]">
        <h2 className="text-2xl font-bold">
          Barlleno Drink Delivery App ipsum dolor sit amet consectetur.
        </h2>
        <p>
          Lorem ipsum dolor sit amet consectetur. Sit pellentesque turpis
          fermentum non. Sem porta bibendum leo sit tortor porttitor. Lorem sem
          diam faucibus lacus vel lorem.
        </p>
        <p>
          Lorem ipsum dolor sit amet consectetur. Sit pellentesque turpis
          fermentum non. Sem porta bibendum leo sit tortor porttitor. Lorem sem
          diam faucibus lacus vel lorem.
        </p>
        <Button className="text-white mt-7 w-full sm:w-max">Conocer la App</Button>
      </div>
    </div>
  );
}
