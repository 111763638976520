
export default function HeaderMain() {
  return (
    <div className="hidden border-none md:flex gap-4 [&>a]:py-[6px] [&>a]:text-center [&>a]:border-solid [&>a]:border-[1px] [&>a]:border-[#E2E2E2]  [&>a]:rounded-md [&>a]:px-2 [&>a]:h-auto [&>a]:text-primary [&>a]:font-bold md:[&>a]:text-[13px]">
      <a href="https://barlleno.app/" target="_blank" rel="noopener noreferrer nofollow">Barlleno App</a>
      <a >Eventos</a>
      <a >On Trade</a>
      <a  href="https://tickets.barlleno.app/" target="_blank" rel="noopener noreferrer nofollow">Tickets</a>
      <a >Barlleno Go</a>
      <a >Blog</a>
    </div>
  );
}
