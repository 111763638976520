import { Button } from "components/ui/button";

export default function HeroSection() {
  return (
    <div className="flex gap-[20px] px-[5%] flex-col sm:flex-row pt-[85px] max-w-[1500px] md:mx-auto">
      <div className="w-full sm:w-[60%] md:w-[40%] flex text-start">
        <img
          src={require("assets/hero.png")}
          className="h-[150px] sm:h-[100%] rounded-2xl w-full object-cover md:object-contain" alt="HeroImage"></img>
      </div>

      <div className="w-full sm:w-[60%] flex flex-col gap-5">
        <h1 className="text-2xl md:text-4xl font-bold">
          Barlleno ipsum dolor sit amet consectetur.
        </h1>
        <p>
          Lorem ipsum dolor sit amet consectetur. Sit pellentesque turpis
          fermentum non. Sem porta bibendum leo sit tortor porttitor. Lorem sem
          diam faucibus lacus vel lorem.
        </p>
        <Button className="bg-primary text-white w-full sm:w-max px-[40px]">Compra Aquí</Button>
      </div>
    </div>
  );
}
