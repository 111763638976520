import { useState } from "react";
import { Sheet, SheetContent, SheetTrigger } from "../ui/sheet";
import { Button } from "../ui/button";
import { MenuIcon } from "lucide-react";
import logo from "assets/logo.svg";
import linkedin from "assets/icons/logo-linkedin.svg";
import fb from "assets/icons/logo-facebook.svg";
import ig from "assets/icons/logo-instagram.svg";

export default function HeaderMobile() {
  const [open, setOpen] = useState(false);
  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>
        <Button variant="ghost" size="icon" className="md:hidden">
          <MenuIcon color="#e84137"/>
        </Button>
      </SheetTrigger>

      <SheetContent className="w-full p-0 bg-[#FAFAFA] h-full">
        <div className="bg-white border-b-[1px] border-[#CED5E0] w-full pb-3 absolute top-0">
          <div>
            <img
              className="w-48 ml-2 mt-3"
              src={logo}
              alt="logo Barlleno"></img>
          </div>
        </div>

        <div className="flex flex-col items-start gap-8 pl-3 pt-[90px] overflow-y-auto h-[90%]">
          {[
            {
              name: "Barlleno App",
              url: "https://barlleno.app/"
            },
            // {
            //   name: "Tickets",
            //   url: "https://tickets.barlleno.app/"
            // },
          
          ].map((item, index) => (
            <div key={index} className="border-b-[1px] border-primary mt-2 w-[90%]">
              <a
                href={item.url}
                target="_blank" rel="noopener noreferrer nofollow"
                className=" text-[#112A56] font-bold text-5xl pb-4"
                onClick={() => {
                  setOpen(false);
                }}>
                {item.name}
              </a>
            </div>
            // <Button
            //   key={index}
            //   variant="link"
            //   onClick={() => {
            //     setOpen(false);
            //   }}>
            //   {item}
            // </Button>
          ))}
        </div>

        <div className="absolute bottom-0 w-full px-6 py-6 bg-white flex justify-between items-center">
          <div className="flex gap-4">
            <a href="https://www.facebook.com/BarllenoApp/" target="_blank" rel="noopener noreferrer nofollow" ><img src={fb} alt="Facebook"></img></a>
            <a href="https://sv.linkedin.com/company/barlleno" target="_blank" rel="noopener noreferrer nofollow"><img src={linkedin} alt="Linkedib"></img></a>
            <a href="https://www.instagram.com/barlleno/" target="_blank" rel="noopener noreferrer nofollow"><img src={ig} alt="instagram"></img></a>
          </div>
          <a href="https://commerce.barlleno.app/" target="_blank" rel="noopener noreferrer nofollow" className="font-bold text-primary text-xl">COMPRAR</a>
        </div>
      </SheetContent>
    </Sheet>
  );
}
