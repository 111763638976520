import { useEffect, useState } from "react";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
  type CarouselApi,
} from "components/ui/carousel";
import { Button } from "components/ui/button";
import Autoplay from "embla-carousel-autoplay";

const defaultInfo = [
  {
    title: "Barlleno Tickets ipsum dolor sit amet consectetur.",
    description:
      "Lorem ipsum dolor sit amet consectetur. Sit pellentesque turpis fermentum non. Sem porta bibendum leo sit tortor porttitor. Lorem sem diam faucibus lacus vel lorem.",
    img: "",
  },
  {
    title: "Barlleno Tickets ipsum dolor sit amet consectetur.",
    description:
      "Lorem ipsum dolor sit amet consectetur. Sit pellentesque turpis fermentum non. Sem porta bibendum leo sit tortor porttitor. Lorem sem diam faucibus lacus vel lorem.",
    img: "",
  },
  {
    title: "Barlleno Tickets ipsum dolor sit amet consectetur.",
    description:
      "Lorem ipsum dolor sit amet consectetur. Sit pellentesque turpis fermentum non. Sem porta bibendum leo sit tortor porttitor. Lorem sem diam faucibus lacus vel lorem.",
    img: "",
  },
  {
    title: "Barlleno Tickets ipsum dolor sit amet consectetur.",
    description:
      "Lorem ipsum dolor sit amet consectetur. Sit pellentesque turpis fermentum non. Sem porta bibendum leo sit tortor porttitor. Lorem sem diam faucibus lacus vel lorem.",
    img: "",
  },
  {
    title: "Barlleno Tickets ipsum dolor sit amet consectetur.",
    description:
      "Lorem ipsum dolor sit amet consectetur. Sit pellentesque turpis fermentum non. Sem porta bibendum leo sit tortor porttitor. Lorem sem diam faucibus lacus vel lorem.",
    img: "",
  },
];

export default function TicketsSection() {
  const [api, setApi] = useState<CarouselApi>();
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    if (!api) {
      return;
    }

    setCurrent(api.selectedScrollSnap() + 1);

    api.on("select", () => {
      setCurrent(api.selectedScrollSnap() + 1);
    });
  }, [api]);

  return (
    <div className="my-8 mx-[10px]">
      <Carousel setApi={setApi} className="w-[80%] max-w-[1200px] mx-auto" plugins={[Autoplay({
          delay: 5000,
        }),]}>
        <CarouselContent>
          {defaultInfo.map((info, index) => (
            <CarouselItem key={index}>
              <div className="flex gap-6 flex-col sm:flex-row">
                <div>
                  <img src={require('assets/tickets.png')} alt="Ticket"></img>
                </div>
                <div className="items-center text-center flex flex-col gap-4">
                  <p className="font-bold text-lg">{info.title}</p>
                  <p>{info.description}</p>
                  <Button className="w-full text-white sm:w-min">Ver más eventos</Button>
                </div>
              </div>
            </CarouselItem>
          ))}
        </CarouselContent>
        <CarouselPrevious className="border-none"/>
        <CarouselNext className="border-none"/>
      </Carousel>
      <div className="flex gap-4 mt-4 justify-center">
        {defaultInfo.map((_, index: number) =>
          current === (index + 1) ? (
            <div key={index} className="rounded-lg p-[6px] bg-primary"></div>
          ) : (
            <div
              onClick={() => {
                api?.scrollTo(index);
              }}
              key={index}
              className="rounded-lg p-[6px] bg-[#D4D4D4]"></div>
          )
        )}
      </div>
    
    </div>
    
  );
}
