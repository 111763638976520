
export default function Card({ title, text, img, marginTopBool }: any) {
  return (
<div className={`flex flex-row gap-5 ${marginTopBool ? "mt-20" : ""}`}>
      <div className="p-[30px] bg-[#FAFAFA] rounded-md flex items-center shadow-md h-fit min-w-[100px]">
        <img className="w-full" src={img} alt={title}></img>
      </div>
      <div className="mt-10">
        <h2 className="text-2xl font-bold">{title}</h2>
        <p>{text}</p>
      </div>
    </div>
  );
}
