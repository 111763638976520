import Layout from "components/layout/layout";
import "./regulations.css";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "components/ui/accordion";
import { Minus, Plus } from "lucide-react";
import { useState } from "react";

export default function Regulations() {
  const [states, setStates] = useState<string | null>(null);

  const changeState = (e: any) => {
    setStates(e);
  };
  return (
    <Layout>
      <div className="bgRegulationsPattern bg-primary pt-24 pb-48 md:pb-8  text-center font-bold">
        <div className="max-w-[1000px] mx-auto [&>*]:text-white px-[5%]">
          <h1 className="text-3xl mb-5">
            En Barlleno, creemos en un futuro más brillante para nuestra
            juventud.
          </h1>
          <p className="text-sm">
            Nos comprometemos a promover la conciencia y la educación sobre el
            consumo responsable de alcohol. Juntos, podemos construir una
            comunidad más segura y saludable para todos. Si eres mayor de edad y
            buscas información sobre el alcohol, estamos aquí para
            proporcionarte información sobre nuestras bebidas. Recuerda, tu
            salud y bienestar son nuestra prioridad
          </p>
        </div>
      </div>

      <div className="relative grid grid-cols-1 md:grid-cols-2 max-w-[1300px] mx-auto px-[5%] gap-6 font-bold mt-7">
        <div className="absolute md:static -top-48 w-full">
          <img
            className="w-[90%] md:w-full md:h-full md:object-cover rounded-xl max-w-[400px] mx-auto lg:max-w-full"
            src={require("../../assets/restrictions/restriction1.webp")}
            alt="Consumo Alcohol"></img>
        </div>
        <div className="flex flex-col justify-between imageResponsive">
          <h2 className="text-primary text-3xl font mb-6 md:mb-0">
            CONOCE LOS RIESGOS DEL CONSUMO DE ALCOHOL EN MENORES DE EDAD
          </h2>
          <div className="[&>*]:text-secondary">
            <h3 className="text-xl mb-3">Información General</h3>
            <p className="font-normal text-sm">
              El alcohol es una sustancia psicoactiva que se encuentra
              comúnmente en bebidas como la cerveza, el vino y las bebidas
              espirituosas. Su principal componente activo es el etanol, el cual
              actúa como depresor del sistema nervioso central. Cuando se
              consume, el alcohol se absorbe rápidamente en el torrente
              sanguíneo y se distribuye por todo el cuerpo, afectando a varios
              sistemas y órganos. Los efectos del alcohol en el cuerpo pueden
              variar dependiendo de factores como la cantidad consumida, la
              frecuencia de consumo, el peso corporal y la tolerancia
              individual. Algunos de los efectos comunes incluyen:
            </p>
          </div>
        </div>
      </div>

      <div className="md:grid md:grid-cols-[1fr_3fr] lg:grid-cols-[2fr_4fr]  max-w-[1200px] xl:mx-auto">
        <div className="bg-primary rounded-xl relative p-8 mx-[5%] mt-8 md:h-[calc(100%_-_30px)] lg:h-min">
          <div className="text-secondary font-bold text-[90px] absolute -top-12 left-0">
            “
          </div>
          <h3 className="text-white font-semibold italic text-2xl">
            El consumo excesivo y prolongado de alcohol puede llevar a problemas
            de salud graves
          </h3>
          <div className="text-secondary font-bold text-[90px] absolute right-0 -bottom-[85px]">
            “
          </div>
        </div>
        <div className="md:flex md:gap-5">
          <div className="mt-10 mx-[5%] md:mx-0 md:w-[50%] md:mt-6">
            <div className="md:h-[55%]">
              <img
                className="md:h-full object-cover rounded-2xl  md:w-full"
                src={require("../../assets/restrictions/restriction2.webp")}
                alt="Efectos"></img>
            </div>

            <div className="mt-4 border-[1px] border-primary p-5 rounded-3xl font-bold text-center md:h-[45%]">
              <h4 className="text-primary text-xl mb-5">Efectos inmediatos</h4>
              <p className="text-secondary">
                Incluyen euforia, desinhibición, pérdida de coordinación motora,
                disminución de la capacidad de juicio y reacción más lenta.
              </p>
            </div>
          </div>
          <div className="mt-6 mx-[5%] md:ml-0 md:w-[50%]">
            <div className="md:h-[30%]">
              <img
                className="md:h-full object-cover rounded-2xl md:w-full"
                src={require("../../assets/restrictions/restriction3.webp")}
                alt="Efectos"></img>
            </div>

            <div className="mt-4 border-[1px] border-primary p-5 rounded-3xl font-bold text-center md:h-[70%]">
              <h4 className="text-primary text-xl mb-5">
                Efectos a largo plazo
              </h4>
              <p className="text-secondary">
                El consumo excesivo y prolongado de alcohol puede llevar a
                problemas de salud graves, como enfermedades del hígado (como la
                cirrosis), trastornos gastrointestinales, enfermedades
                cardiovasculares, deterioro del sistema inmunológico, y daño
                cerebral.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="bgRegulationsPattern mt-10 bg-primary py-10 px-[5%] text-center font-bold [&>div]:text-white text-2xl">
        <div className="max-w-[1300px] mx-auto">
          Cuando se trata de jóvenes cuyos cuerpos y cerebros aún están en
          desarrollo, el consumo de alcohol puede ser particularmente peligroso
          y perjudicial.
        </div>
      </div>

      <div className="hidden md:grid grid-cols-2 gap-6 px-[2%] mt-10 max-w-[1300px] lg:mx-auto">
        <div className="flex flex-col gap-5">
          <img
            src={require("../../assets/restrictions/restriction4.webp")}
            alt="Desarrollo Cerebral"></img>
          <div className="border-[1px] rounded-3xl border-primary p-5">
            <h2 className="font-bold text-2xl text-primary mb-5">
              Mayor vulnerabilidad
            </h2>
            <p className="text-secondary">
              Los jóvenes pueden ser más sensibles a los efectos del alcohol
              debido a su menor tolerancia y peso corporal. Esto significa que
              pueden embriagarse más rápidamente y con cantidades menores de
              alcohol, lo que aumenta el riesgo de intoxicación aguda y otros
              daños.
            </p>
          </div>
          <img
            src={require("../../assets/restrictions/restriction5.webp")}
            alt="Desarrollo Cerebral"></img>
        </div>
        <div className="flex flex-col gap-5">
          <div className="border-[1px] rounded-3xl border-primary p-5">
            <h2 className="font-bold text-2xl text-primary mb-5">
              Desarrollo cerebral
            </h2>
            <p className="text-secondary">
              El cerebro sigue desarrollándose hasta bien entrada la veintena, y
              el alcohol puede interferir en este proceso. Puede afectar áreas
              del cerebro responsables del aprendizaje, la memoria y la toma de
              decisiones, lo que puede tener consecuencias a largo plazo en el
              desarrollo cognitivo y emocional.
            </p>
          </div>
          <img
            src={require("../../assets/restrictions/restriction6.webp")}
            alt="Desarrollo Cerebral"></img>
          <div className="border-[1px] rounded-3xl border-primary p-5">
            <h2 className="font-bold text-2xl text-primary mb-5">
              Riesgo de adicción
            </h2>
            <p className="text-secondary">
              El consumo temprano de alcohol puede aumentar el riesgo de
              desarrollar problemas de abuso de alcohol o dependencia en el
              futuro. El inicio temprano del consumo de alcohol está asociado
              con un mayor riesgo de desarrollar trastornos por consumo de
              alcohol más adelante en la vida.
            </p>
          </div>
        </div>
      </div>

      <div className="mx-[5%] mt-10 md:hidden">
        <Accordion
          type="single"
          collapsible
          onValueChange={(e) => {
            changeState(e);
          }}>
          <AccordionItem
            value="item-1"
            className="border-[1px] rounded-3xl border-primary">
            <AccordionTrigger className="hover:no-underline">
              <div>
                <img
                  src={require("../../assets/restrictions/restriction4.webp")}
                  alt="Desarrollo Cerebral"></img>
                <div className="flex justify-between p-3 items-center">
                  <p className="font-bold text-primary text-xl">
                    Desarrollo Cerebral
                  </p>
                  <div className="bg-primary rounded-full p-1 h-min flex items-center">
                    {states === "item-1" ? (
                      <Minus className="text-white" color="white" size={16} />
                    ) : (
                      <Plus className="text-white" color="white" size={16} />
                    )}
                  </div>
                </div>
              </div>
            </AccordionTrigger>
            <AccordionContent className="px-[10px] text-secondary">
              El cerebro sigue desarrollándose hasta bien entrada la veintena, y
              el alcohol puede interferir en este proceso. Puede afectar áreas
              del cerebro responsables del aprendizaje, la memoria y la toma de
              decisiones, lo que puede tener consecuencias a largo plazo en el
              desarrollo cognitivo y emocional.
            </AccordionContent>
          </AccordionItem>

          <AccordionItem
            value="item-2"
            className="border-[1px] rounded-3xl border-primary mt-7">
            <AccordionTrigger className="hover:no-underline">
              <div>
                <img
                  src={require("../../assets/restrictions/restriction5.webp")}
                  alt="Riesgo de adicción"></img>
                <div className="flex justify-between p-3 items-center">
                  <p className="font-bold text-primary text-xl">
                    Riesgo de adicción
                  </p>
                  <div className="bg-primary rounded-full p-1 h-min flex items-center">
                    {states === "item-2" ? (
                      <Minus className="text-white" color="white" size={16} />
                    ) : (
                      <Plus className="text-white" color="white" size={16} />
                    )}
                  </div>
                </div>
              </div>
            </AccordionTrigger>
            <AccordionContent className="px-[10px] text-secondary">
              El consumo temprano de alcohol puede aumentar el riesgo de
              desarrollar problemas de abuso de alcohol o dependencia en el
              futuro. El inicio temprano del consumo de alcohol está asociado
              con un mayor riesgo de desarrollar trastornos por consumo de
              alcohol más adelante en la vida.
            </AccordionContent>
          </AccordionItem>

          <AccordionItem
            value="item-3"
            className="border-[1px] rounded-3xl border-primary mt-7">
            <AccordionTrigger className="hover:no-underline">
              <div>
                <img
                  src={require("../../assets/restrictions/restriction6.webp")}
                  alt="Mayor vulnerabilidad"></img>
                <div className="flex justify-between p-3 items-center">
                  <p className="font-bold text-primary text-xl">
                    Mayor vulnerabilidad
                  </p>
                  <div className="bg-primary rounded-full p-1 h-min flex items-center">
                    {states === "item-3" ? (
                      <Minus className="text-white" color="white" size={16} />
                    ) : (
                      <Plus className="text-white" color="white" size={16} />
                    )}
                  </div>
                </div>
              </div>
            </AccordionTrigger>
            <AccordionContent className="px-[10px] text-secondary">
              Los jóvenes pueden ser más sensibles a los efectos del alcohol
              debido a su menor tolerancia y peso corporal. Esto significa que
              pueden embriagarse más rápidamente y con cantidades menores de
              alcohol, lo que aumenta el riesgo de intoxicación aguda y otros
              daños.
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>

      <div className="py-5 mt-10 bg-[#F4F4F5]">
        <div className="md:grid px-[5%] grid-cols-2 items-center max-w-[1300px] lg:mx-auto">
          <div className="">
            <h2 className="text-secondary text-2xl font-bold mb-5">
              Leyes y Regulaciones
            </h2>
            <p className="text-secondary">
              En El Salvador, la Ley de Regulación y Control de Bebidas
              Alcohólicas, establece regulaciones relacionadas con el consumo de
              alcohol, incluyendo disposiciones sobre la venta y el suministro
              de alcohol a menores de edad.
            </p>
          </div>
          <div className="mt-5 grid grid-cols-[2fr_1fr] gap-4">
            <img
              className="h-full max-h-52"
              src={require("../../assets/restrictions/restriction7.webp")}
              alt="No Alcohol"></img>
            <img className="h-full max-h-52"
              src={require("../../assets/restrictions/restriction8.webp")}
              alt="Mayor de 18"></img>
          </div>
        </div>

        <div className="mt-5 flex gap-3 px-[3%] overflow-auto max-w-[1300px] lg:mx-auto">
          <div className="bg-primary [&>*]:text-white p-5 rounded-3xl min-w-[320px]  sm:min-w-[45%] ml-4">
            <h3 className="font-bold text-2xl pb-4 mb-4 border-b-[1.5px]">
              Venta y suministro a menores
            </h3>
            <p>
              Está prohibida la venta, suministro, distribución y entrega de
              bebidas alcohólicas a menores de 18 años en cualquier
              establecimiento público o privado. Esto incluye bares,
              restaurantes, tiendas y otros puntos de venta.
            </p>
          </div>
          <div className="bg-primary [&>*]:text-white p-5 rounded-3xl min-w-[320px] sm:min-w-[45%] mr-5">
            <h3 className="font-bold text-2xl pb-4 mb-4 border-b-[1.5px]">
              Consumo en lugares públicos
            </h3>
            <p>
              También está prohibido que los menores de 18 años consuman bebidas
              alcohólicas en lugares públicos, como calles, plazas, parques y
              otros espacios de acceso público.
            </p>
          </div>
        </div>
      </div>

      <div className="mx-[5%] my-10 md:grid grid-cols-[2fr_3fr] lg:flex md:gap-5 max-w-[1300px] lg:px-[2%] lg:mx-auto">
        <div className="hidden md:flex md:flex-col gap-6 h-full lg:min-w-[310px] lg:max-w-[310px] riskImageContainer">
          <div className="h-1/2 min-h-1/2 max-h-1/2">
            <img
              className="w-full rounded-3xl md:h-full md:object-cover"
              src={require("../../assets/restrictions/restriction9.webp")}
              alt="Problemas de desarrollo cerebral"></img>
          </div>
          <div className="h-1/2 min-h-1/2 max-h-1/2">
            <img
              className="w-full rounded-3xl md:h-full md:object-cover md:object-left"
              src={require("../../assets/restrictions/restriction10.webp")} 
              alt="Alteraciones en el patrón de sueño"></img>
          </div>
        </div>
        <div>
          <h2 className="text-secondary font-bold text-2xl my-5 md:mt-0">
            Riesgos para la Salud
          </h2>
          <p className="text-secondary">
            El consumo de alcohol en menores de edad conlleva una serie de
            riesgos específicos que pueden tener impactos duraderos en su salud
            y bienestar.
          </p>

          <div className="relative border-[1px] border-primary rounded-2xl mt-40 px-[15px] pb-5 md:mt-5">
            <img
              className="absolute -top-36 w-44 rounded-3xl h-[190px] object-cover object-left-bottom md:hidden"
              src={require("../../assets/restrictions/restriction9.webp")}
              alt="Problemas de desarrollo cerebral"></img>
            <h3 className="text-primary font-bold text-xl mt-16 mb-5 md:mt-5">
              Problemas de desarrollo cerebral
            </h3>
            <p className="text-secondary">
              El cerebro de los adolescentes está en un proceso continuo de
              desarrollo y maduración, y el alcohol puede interferir en este
              proceso. El consumo de alcohol durante la adolescencia puede
              afectar negativamente áreas del cerebro involucradas en funciones
              cognitivas como el aprendizaje, la memoria y la toma de
              decisiones. Esto puede resultar en dificultades académicas,
              problemas de atención y control de impulsos, y afectar
              negativamente su capacidad para alcanzar su máximo potencial
              cognitivo.
            </p>
          </div>

          <div className="relative border-[1px] border-primary rounded-2xl mt-44 px-[15px] pb-5 md:mt-5">
            <img
              className="absolute -top-36 w-44 rounded-3xl h-[190px] object-cover object-left-bottom md:hidden"
              src={require("../../assets/restrictions/restriction10.webp")}
              alt="Problemas de desarrollo cerebral"></img>
            <h3 className="text-primary font-bold text-xl mt-16 mb-5 md:mt-5">
              Alteraciones en el patrón de sueño
            </h3>
            <p className="text-secondary">
              El alcohol puede alterar el patrón de sueño en los adolescentes,
              lo que puede llevar a problemas de insomnio, dificultades para
              conciliar el sueño y un sueño de baja calidad. El sueño es crucial
              para el crecimiento y desarrollo saludables, así como para el
              funcionamiento cognitivo y emocional adecuado, por lo que las
              alteraciones en el sueño pueden tener efectos adversos en la salud
              física y mental de los adolescentes.
            </p>
          </div>
        </div>
      </div>

      <div className="bgRegulationsPattern bg-secondary pt-12 pb-12 px-[5%] text-center font-bold [&>*]:text-white">
        <h2 className="pb-4 text-2xl max-w-[1300px] lg:mx-auto">
          Si estas experimentando problemas y necesitas apoyo o quieres
          información sobre centros de ayuda entra al siguiente enlace
        </h2>
        <a
          href="https://fosalud.gob.sv/servicios/servicios-4/"
          rel="noopener noreferrer nofollow"
          className="uppercase underline text-xl max-w-[1300px] lg:mx-auto">
          Información Aquí
        </a>
      </div>
    </Layout>
  );
}
