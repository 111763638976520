import logo from "assets/logo_ontrade.svg";
import { Button } from "components/ui/button";

export default function OnTrade() {
  return (
    <div className="bg-[#FAFAFA] py-10">
      <div className="flex justify-center mb-6">
        <img className="w-[200px]" src={logo} alt="onTrade Logo"></img>
      </div>
      <div className="text-center">
        <div className="w-full text-center px-[5%] md:px-[20%]">
          <h2 className="text-2xl font-bold">
            OnTrade ipsum dolor sit amet consectetur.
          </h2>
          <p>
            Lorem ipsum dolor sit amet consectetur. Sit pellentesque turpis
            fermentum non. Sem porta bibendum leo sit tortor porttitor. Lorem
            sem diam faucibus lacus vel lorem.
          </p>
        </div>
        <div className="flex px-[10%] gap-[25%] sm:gap-8 flex-nowrap overflow-scroll md:overflow-hidden mt-6 max-w-[1500px] lg:mx-auto">
          <div className="flex flex-col min-w-[200px] lg:w-11/12 justify-center items-center sm:w-2/3">
            <img
              className="w-full md:w-2/5 lg:w-[60%] lg:max-w-[400px]"
              src={require("assets/ontrade/Rectangle 189.png")}
              alt=""
            />
            <div className="w-full sm:w-[90%]">
              <h3 className="text-xl font-semibold">
                Lorem ipsum dolor sit amet consectetur.
              </h3>
              <p>
                Lorem ipsum dolor sit amet consectetur. Sit pellentesque turpis
                fermentum non. Sem porta bibendum leo sit tortor porttitor.
                Lorem sem diam faucibus lacus vel lorem.
              </p>
            </div>
          </div>
          <div className="flex flex-col min-w-[200px] lg:w-11/12 justify-center items-center sm:w-2/3">
            <img
              className="w-full md:w-2/5 lg:w-[60%] lg:max-w-[400px]"
              src={require("assets/ontrade/Rectangle 189.png")}
              alt=""
            />
            <div className="w-full sm:w-[90%]">
              <h3 className="text-xl font-semibold">
                Lorem ipsum dolor sit amet consectetur.
              </h3>
              <p>
                Lorem ipsum dolor sit amet consectetur. Sit pellentesque turpis
                fermentum non. Sem porta bibendum leo sit tortor porttitor.
                Lorem sem diam faucibus lacus vel lorem.
              </p>
            </div>
          </div>
          <div className="flex flex-col min-w-[200px] lg:w-11/12 justify-center items-center sm:w-2/3">
            <img
              className="w-full md:w-2/5 lg:w-[60%] lg:max-w-[400px]"
              src={require("assets/ontrade/Rectangle 189.png")}
              alt=""
            />
            <div className="w-full sm:w-[90%]">
              <h3 className="text-xl font-semibold">
                Lorem ipsum dolor sit amet consectetur.
              </h3>
              <p>
                Lorem ipsum dolor sit amet consectetur. Sit pellentesque turpis
                fermentum non. Sem porta bibendum leo sit tortor porttitor.
                Lorem sem diam faucibus lacus vel lorem.
              </p>
            </div>
          </div>
        </div>
        <div className="mx-[5%]">
          <Button className="text-white mt-10 w-full sm:w-max">
            Lorem ipsum dolor
          </Button>
        </div>
      </div>
    </div>
  );
}
