import HeroSection from "../components/hero/hero";
import Card from "components/card/card";
import AppDetails from "../components/appDetails/appDetails";
import BarllenoEventSection from "../components/barllenoEvents/barllenoEvent";
import OnTrade from "app/home/components/onTrade/onTrade";
import TicketsSection from "../components/tickets/tickets";
import GoSection from "../components/go/go";
import BlogSection from "../components/blog/blog";

import { Button } from "components/ui/button";
import { Divider } from "components/divider/divider";

/* IMAGES */
import targetIcon from "assets/icons/target.svg";
import rocketIcon from "assets/icons/rocket.svg";
import binocularsIcon from "assets/icons/binoculars.svg";
import diamondIcon from "assets/icons/diamond.svg";
import useSize from "hooks/useScreenSize";
import { useEffect, useState } from "react";
import Layout from "components/layout/layout";

const info = [
  {
    title: "Objetivos ipsum dolor sit amet consectetur.",
    text: "Lorem ipsum dolor sit amet consectetur. Sit pellentesque turpis fermentum non. Sem porta bibendum leo sit tortor porttitor. Lorem sem diam faucibus lacus vel lorem.",
    img: targetIcon,
  },
  {
    title: "Objetivos ipsum dolor sit amet consectetur.",
    text: "Lorem ipsum dolor sit amet consectetur. Sit pellentesque turpis fermentum non. Sem porta bibendum leo sit tortor porttitor. Lorem sem diam faucibus lacus vel lorem.",
    img: rocketIcon,
  },
  {
    title: "Objetivos ipsum dolor sit amet consectetur.",
    text: "Lorem ipsum dolor sit amet consectetur. Sit pellentesque turpis fermentum non. Sem porta bibendum leo sit tortor porttitor. Lorem sem diam faucibus lacus vel lorem.",
    img: binocularsIcon,
  },
  {
    title: "Objetivos ipsum dolor sit amet consectetur.",
    text: "Lorem ipsum dolor sit amet consectetur. Sit pellentesque turpis fermentum non. Sem porta bibendum leo sit tortor porttitor. Lorem sem diam faucibus lacus vel lorem.",
    img: diamondIcon,
  },
];

export const HomePage = () => {
  const [windowsize, setWindowsize] = useState(useSize())
  useEffect(()=>{
    console.log(windowsize)
  },[windowsize])
  return (
    <Layout>
      <HeroSection></HeroSection>

      <div className={`grid sm:grid-cols-2 grid-cols-1 gap-[20px] px-[5%] mt-10 max-w-[1500px] md:mx-auto`}>
        {info.map((inf, index) =>
          index % 2 === 0 ? (
            <Card
              key={index}
              title={inf.title}
              img={inf.img}
              text={inf.text}></Card>
          ) : (
            <Card
              key={index}
              title={inf.title}
              img={inf.img}
              text={inf.text}
              marginTopBool={windowsize[1] > 640 ? true: false}></Card>
          )
        )}
      </div>
      <div className="flex justify-center mt-10">
        <Button className="text-white px-7">Más Información</Button>
      </div>

      <AppDetails></AppDetails>

      <BarllenoEventSection></BarllenoEventSection>

      <OnTrade></OnTrade>

      <TicketsSection></TicketsSection>

      <Divider></Divider>
      <GoSection></GoSection>
      <BlogSection></BlogSection>
    </Layout>
  );
};
