import HeaderMain from "components/headerMain/headerMain";
import logo from "assets/logo.svg";
import HeaderMobile from "components/headerMobile/headerMobile";

export default function Header() {
  return (
    <div className="flex flex-row justify-between px-[5%] py-10 items-end xs:py-4 fixed bg-white w-full border-[1px] border-b-[#ECEEF0] z-50">
      <div>
        <img className="max-w-[160px]" src={logo} alt="Logo"></img>
      </div>
      <HeaderMobile></HeaderMobile>
      <HeaderMain></HeaderMain>
    </div>
  );
}
