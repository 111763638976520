import Layout from "components/layout/layout";
import delirium from "../../assets/nonpage/delirium.webp";
import licor43 from "../../assets/nonpage/licor43.webp";
import titosvodka from "../../assets/nonpage/TitosVodka.webp";
import bulldog from "../../assets/nonpage/bulldog.webp";
import perrier from "../../assets/nonpage/Perrier.webp";
import mahou from "../../assets/nonpage/mahou.webp";

export default function NonExistPage() {
  return (
    <Layout>
      <div className="bgRegulationsPattern bg-primary pt-20 md:pt-48 md:pb-20">
        <div className="flex flex-col md:flex-row text-center md:justify-center [&>*]:font-bold max-w-[500px] md:max-w-[1400px]  mx-auto">
          <div className="relative h-40 md:h-auto w-full md:w-[40%] lg:w-[50%]">
            <img
              className="absolute w-[20%] md:w-[30%] md:-top-[20%] md:max-h-none max-h-28 object-contain top-0 left-0"
              src={delirium}
              alt="Delirium"></img>
            <img
              className="absolute w-[30%] md:w-[45%] md:max-h-60 max-h-40 object-contain top-0 md:top-auto md:-bottom-[10%] left-[32%]"
              src={licor43}
              alt="Licor 43"></img>
            <img
              className="absolute w-[30%] md:w-[40%] md:max-h-52 max-h-40 object-contain md:bottom-auto md:-top-[10%] -bottom-10 right-0"
              src={titosvodka}
              alt="Tito's Vodka"></img>
          </div>
          <div className="md:w-fit">
            <h1 className="text-white text-4xl md:text-6xl font-bold uppercase">
              Error
            </h1>
            <div className="flex gap-4 justify-center [&>*]:text-white md:[&>p]:text-[160px] [&>p]:text-8xl [&>*]:font-bold">
              <p>4</p>
              <img
                className="w-[30%] max-w-[120px] md:w-[100%] md:max-w-[190px]"
                src="/logo.svg"
                alt="Logo Barlleno"></img>
              <p>4</p>
            </div>
            <p className="text-white text-sm uppercase mt-5 mb-10 md:mb-10">
              ¡Parece que has llegado muy lejos!
            </p>
            <a aria-label="Seguir Comprando" rel="noreferrer" href="https://commerce.barlleno.app/login?redirectOnSucces=%2Ftabs%2Fhome" target="_blank" className="bg-white w-fit px-3 py-2 mx-auto text-primary rounded mt-4 ">
              Seguir Comprando
            </a>
          </div>
          <div className="relative h-40 md:h-auto md:w-[40%] lg:w-[50%]">
            <img
              className="absolute -top-[20%] md:top-auto md:-bottom-[15%] left-0 w-[30%] md:w-[50%] md:max-h-80 max-h-40 object-contain"
              src={bulldog}
              alt="Delirium"></img>
            <img
              className="absolute top-0 left-[35%] w-[30%] max-h-40 md:w-[40%] md:left-[45%] md:max-h-56 object-contain"
              src={perrier}
              alt="Licor 43"></img>
            <img
              className="absolute bottom-0 md:top-auto md:-bottom-[10%] right-0 w-[30%] md:w-[35%] md:max-h-72 max-h-40 object-contain"
              src={mahou}
              alt="Tito's Vodka"></img>
          </div>
        </div>
      </div>
    </Layout>
  );
}
